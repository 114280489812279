import React, { createContext, useState, useEffect, ReactNode } from 'react';
import api from '@/api/login';
import { message } from 'antd';

interface MemberOption {
    updataTip: string;
    icon: string;
    tit: string;
    price: string;
    tip: string;
    prizeTip: string;
    isMax: boolean;
    isMost: boolean;
    subscriptionId: number;
    subscriptionPackageIds: number[];
    infoList: string[];
}

interface MemberOptionContextType {
    globalData: MemberOption[] | null;
    maxHeight: number | 623;
}

export const MemberOptionContext = createContext<MemberOptionContextType | null>(null);

interface MemberOptionProviderProps {
    children: ReactNode;
}

export const MemberOptionProvider: React.FC<MemberOptionProviderProps> = ({ children }) => {
    const [globalData, setGlobalData] = useState<any>([
        {
            updataTip: 'Available prizes in the Bronze membership',
            icon: require('../assets/img/home/copper.png'),
            tit: 'Bronze',
            price: '$20,000',
            tip: 'Bronze Membership Holders Can Only Enter Bronze Prize Draw',
            prizeTip: 'Bronze - $20,000 Maximum',
            isMax: false,
            isMost: true,
            subscriptionId: 1,
            subscriptionPackageIds: [1, 2, 3],
            infoList: [
                'Concert packages(BOXES)',
                'Footyshow Packages(SYD/MELB-Boxes)',
                'Shopping Vouchers for entire home of furnishings(Havey norman & others)',
                'Groceries Paid for 1 years ($20,000)',
                'Fuel Paid for 1 years ($10,000)',
                'Club memberships - 5 years(AFL/NRL/FFA)',
                'Yearly utilities package(water, elec, gas, rates) (maximum $5000)',
                'Insurance packages(health, motor, vehicles, life - maximum $7500)',
            ],
        },
        {
            updataTip: 'Available prizes in the Silver membership',
            icon: require('../assets/img/home/silver.png'),
            tit: 'Silver',
            price: '$50,000+',
            tip: 'Silver Membership Holders Can Only Enter Bronze And Silver Prize Draws',
            prizeTip: 'Silver - $50,000+ Maximum',
            isMax: false,
            isMost: true,
            subscriptionId: 2,
            subscriptionPackageIds: [4, 5, 6],
            infoList: [
                "Holiday Packages (Accom', Airfare, Tours)",
                'M.C.G. Boxes (Cricket, Footy, Soccer)',
                'C.C.A Boxes',
                'S.C.G. Boxes',
                'House Boat Holidays - Hawkesbury / Murray',
                'University Fees Paid (Aust Wide)',
                'Rent or Mortgage Paid For 1 Year (Maximum $25,000)',
                'Helicopter Flights',
                'Your deposit on your own home $50,000 (made payable to a particular contract).',
            ],
        },
        {
            updataTip: 'Available prizes in the Gold membership',
            icon: require('../assets/img/home/gold.png'),
            tit: 'Gold',
            price: '$120,000+',
            tip: 'Gold Membership Holders Can Enter All Prize Draws',
            prizeTip: 'Gold - $120,000+ Maximum',
            isMax: false,
            isMost: false,
            subscriptionId: 3,
            subscriptionPackageIds: [7, 8, 9],
            infoList: [
                'House & Land Packages $500,000',
                'House - $300,000',
                'Land - $200,000',
                'Investment Portofolio Y.B.R. - $250K',
                'Car (4WD) & Caravan Packages (INCL’ INS’) - $120,000',
                '1st Class Airfare Upgrade on all Flights Taken',
                'Rent or Mortgage Paid For 1 Year (Maximum $25,000)',
                'Helicopter Flights',
                'Your deposit on your own home $50,000 (made payable to a particular contract).',
            ],
        },
    ]);
    const [maxHeight, setMaxHeight] = useState<number>(0);
    function formatPrice(value) {
        const parts = value.split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const decimalPart = +parts[1] ? parts[1].substring(0, 2) : '';
        const newVal = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
        return newVal;
    }
    useEffect(() => {
        try {
            api.getSubscriptionList((res: any) => {
                const { code, data } = res;
                if (code === 200) {
                    if (data.length) {
                        globalData?.forEach((item, i) => {
                            const nowVal: any = JSON.parse(
                                JSON.stringify(data[data.findIndex(v => +v.subscriptionId === item.subscriptionId)])
                            );
                            if (nowVal.estimatedPrice && nowVal.estimatedPrice !== null) {
                                nowVal.estimatedPrice = formatPrice(nowVal.estimatedPrice.toString());
                            }
                            item.price = nowVal.estimatedPrice ? `$${nowVal.estimatedPrice}` : '$0';
                            item.prizeTip = `${item.tit} - ${
                                nowVal.estimatedPrice !== null ? '$' + nowVal.estimatedPrice : 0
                            } ${nowVal.isMaximum ? ' Maximum' : ''}`;
                            item.isMax = nowVal.isMaximum;
                            item.isMost = nowVal.mostPopular;
                            item.infoList =
                                nowVal.description && nowVal.description !== null
                                    ? nowVal.description.split(',8848,')
                                    : [];
                        });
                        setGlobalData([...globalData]);
                    }
                } else {
                    message.error(res.message);
                }
            });
        } catch (error) {
            message.error('Failed to fetch data');
        }
    }, []);
    // const getMaxHeight = () => {
    // setTimeout(() => {
    //     const elements = document.querySelectorAll('.sub_content_h5');
    //     let maxHeightValue = 0;

    //     elements.forEach(element => {
    //         const height = (element as HTMLElement).offsetHeight;
    //         if (height > maxHeightValue) {
    //             maxHeightValue = height;
    //         }
    //     });
    //     setMaxHeight(maxHeightValue);
    //     elements.forEach(element => {
    //         (element as HTMLElement).style.height = `${maxHeightValue}px`;
    //     });
    // }, 500);
    // };
    // useEffect(() => {
    //     if (globalData) {
    //         getMaxHeight();
    //     }
    // }, [globalData]);

    return <MemberOptionContext.Provider value={{ globalData, maxHeight }}>{children}</MemberOptionContext.Provider>;
};
