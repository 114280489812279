import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import api from '@/api/home';
import './style/prize.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
import PrizesAvailableMember from '@/components/prizes-available-member';
import NumberInput from '@/components/number-input';
import { defaultInfo } from '@/typings/allType';
import useLoading from '@/hook/useLoading';
import memberData from '@/utils/member';
import 'wc-waterfall';
import { MemberOptionContext } from '@/context/MemberOptionProvider';
const Prize = (props: any) => {
    const memberOptions: any = useContext(MemberOptionContext);
    const { deviceType, profileInfo } = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const subscriptionList = memberData.subscriptionList;
    const { mainLoading, setLoadingStatus } = useLoading();
    const [subKey, setSubKey] = useState(0);
    const [opeationModalOpen, setOpeationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [opeationPrize, setOpeationPrize] = useState<any>({});
    const [prizeInfo, setPrizeInfo] = useState<any>({
        pointsOwn: 0,
        pointsOwnShow: 0,
        subscriptionPackageId: '1', // 下级订阅id
    });
    const [prizeList, setPrizeList] = useState<any[]>([]);
    const confirmEnter = () => {
        setLoading(true);
        const pat = {
            number: opeationPrize.prizeNum,
            prizeId: opeationPrize.prizeId,
        };
        api.userLottery(pat, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                data.drawDate = data.drawDate ? window.utils.foramtMonthDayTime(+data.drawDate) : '-';
                data.pointsOwn = data.pointsOwn ? window.utils.formatPonit(data.pointsOwn) : '0';
                window.sessionStorage.setItem('lotteryInfo', JSON.stringify(data));
                navigate('/enterSuccess');
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const getList = () => {
        setLoadingStatus(true);
        try {
            api.getPrizeList(res => {
                const { data, code, message } = res;
                setLoadingStatus(false);
                if (code === 200) {
                    if (data && data !== null) {
                        console.log(data);
                        data.pointsOwnShow = window.utils.formatPonit(data.pointsOwn ?? 0);
                        setPrizeInfo(data);
                        if (data.userPrizeList !== null && data.userPrizeList.length) {
                            const list = data.userPrizeList.map(item => {
                                return Object.assign(item, {
                                    prizeNum: 0,
                                    maxPrzie: data.pointsOwn ? parseInt((data.pointsOwn / item.drawPoints) as any) : 0,
                                    drawPointsShow:
                                        item.drawPoints !== null ? window.utils.formatPonit(item.drawPoints) : 0,
                                });
                            });
                            setPrizeList([...list]);
                        }
                    }
                } else {
                    window.format.alert('error', message, code);
                }
            });
        } catch (error) {
            setLoadingStatus(false);
        }
    };
    const updateMember = () => {
        const nextSubId = !Number(profileInfo.subscriptionId)
            ? 1
            : Number(profileInfo.subscriptionId) === 3
            ? 3
            : Number(profileInfo.subscriptionId) + 1;
        const index = !Number(profileInfo.subscriptionId)
            ? 0
            : memberData.subscriptionList.findIndex(v => v.subscriptionId === nextSubId);
        navigate(
            `/memberOption?type=2&subscriptionId=${memberData.subscriptionList[index].subscriptionId}&subscriptionPackageId=${profileInfo.subscriptionPackageId}`
        );
    };
    useEffect(() => {
        // console.log(profileInfo);
        if (profileInfo.isExpired) {
            navigate('/introductionPage?type=2');
            return;
        }
        const subKey = subscriptionList.findIndex(v => v.subscriptionId === +profileInfo.subscriptionId);
        setSubKey(subKey === -1 ? 0 : subKey);
        getList();
    }, []);
    return (
        <>
            {!mainLoading ? (
                <div className="prize-main">
                    <div className="zIndexTop">
                        <div className="r-center">
                            <img src={logoIcon} className="logo-box" alt="" />
                        </div>
                        <section className="prize-content">
                            <div className="r-left profile-numBox">
                                <div className="statistics-card gift-card">
                                    Your current available reward balance
                                    <div className="sta-num gift-num">{prizeInfo.pointsOwnShow}</div>
                                </div>
                            </div>
                            <div className="pad24">
                                <div className="bronze-box">
                                    <h1 className="bronze-h1">{memberOptions?.globalData[subKey].prizeTip ?? '-'}</h1>
                                    {prizeList.length ? (
                                        <>
                                            <section className="bronzeList-box">
                                                {deviceType === 'pc' ? (
                                                    <wc-waterfall gap={35} cols={3} className="r-left bronzeList-box">
                                                        {prizeList.map((val, k) => {
                                                            return (
                                                                <div className="bronzeCatd" key={`prizeCard-${k}`}>
                                                                    <div className="r-left">
                                                                        <p className="prize-name">
                                                                            {val.prizeName}({val.prizeDescription})
                                                                        </p>
                                                                        <NumberInput
                                                                            className={'marLeft'}
                                                                            prizeNum={val.prizeNum}
                                                                            maxPrzie={val.maxPrzie}
                                                                            isDisabled={val.isStockZero}
                                                                            index={k}
                                                                            val={prizeList}
                                                                            setPrizeList={setPrizeList}
                                                                        ></NumberInput>
                                                                    </div>
                                                                    <div className="r-left mart16">
                                                                        <div className="prize-points">
                                                                            {val.drawPointsShow}{' '}
                                                                            <span className="pointTip">
                                                                                {val.drawPoints > 1
                                                                                    ? 'Points'
                                                                                    : 'Point'}
                                                                            </span>
                                                                        </div>
                                                                        {val.isStockZero ? (
                                                                            <span className="prizeCheckout-btn r-center">
                                                                                out of stock
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className="prizeEnter-btn r-center"
                                                                                onClick={() => {
                                                                                    if (!val.prizeNum) {
                                                                                        window.utils.alert(
                                                                                            'error',
                                                                                            'Please select the number of draws you want'
                                                                                        );
                                                                                        return;
                                                                                    }
                                                                                    val.needPoints = (
                                                                                        val.prizeNum * val.drawPoints
                                                                                    ).toFixed(0);
                                                                                    setOpeationPrize(val);
                                                                                    setOpeationModalOpen(true);
                                                                                }}
                                                                            >
                                                                                Enter
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </wc-waterfall>
                                                ) : (
                                                    <div className="r-left bronzeList-box">
                                                        {prizeList.map((val, k) => {
                                                            return (
                                                                <div className="bronzeCatd" key={`prizeCard-${k}`}>
                                                                    <div className="r-left">
                                                                        <p className="prize-name">
                                                                            {val.prizeName}({val.prizeDescription})
                                                                        </p>
                                                                        <NumberInput
                                                                            className={'marLeft'}
                                                                            prizeNum={val.prizeNum}
                                                                            maxPrzie={val.maxPrzie}
                                                                            isDisabled={val.isStockZero}
                                                                            index={k}
                                                                            val={prizeList}
                                                                            setPrizeList={setPrizeList}
                                                                        ></NumberInput>
                                                                    </div>
                                                                    <div className="r-left mart16">
                                                                        <div className="prize-points">
                                                                            {val.drawPointsShow}{' '}
                                                                            <span className="pointTip">
                                                                                {val.drawPoints > 1
                                                                                    ? 'Points'
                                                                                    : 'Point'}
                                                                            </span>
                                                                        </div>
                                                                        {val.isStockZero ? (
                                                                            <span className="prizeCheckout-btn r-center">
                                                                                out of stock
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className="prizeEnter-btn r-center"
                                                                                onClick={() => {
                                                                                    if (!val.prizeNum) {
                                                                                        window.utils.alert(
                                                                                            'error',
                                                                                            'Please select the number of draws you want'
                                                                                        );
                                                                                        return;
                                                                                    }
                                                                                    val.needPoints = (
                                                                                        val.prizeNum * val.drawPoints
                                                                                    ).toFixed(0);
                                                                                    setOpeationPrize(val);
                                                                                    setOpeationModalOpen(true);
                                                                                }}
                                                                            >
                                                                                Enter
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                                <div className="prize_tipBtm r-left">
                                                    <i className="tip_icon"></i>
                                                    {subscriptionList[subKey].tip ??
                                                        'Bronze Membership Holders Can Only Enter Bronze Prize Draw'}
                                                </div>
                                            </section>
                                        </>
                                    ) : (
                                        <div className="noMessage-box r-center">Prizes have not been configured.</div>
                                    )}
                                </div>
                            </div>
                            <div className="member-box">
                                <div className="r-left mem-topbox">
                                    <i className="lock-icon"></i>
                                    <div>
                                        <div className="mem-t">Other Prizes</div>
                                        <div className="mem-tip">Upgrade to access these prizes</div>
                                    </div>
                                </div>
                                <PrizesAvailableMember
                                    className="minMar18"
                                    initialSlideIndex={+prizeInfo.subscriptionPackageId - 1}
                                ></PrizesAvailableMember>
                                <div className="r-center pad24">
                                    <Button className="r-primary_btn upgrade-btn" onClick={() => updateMember()}>
                                        Upgrade
                                    </Button>
                                </div>
                            </div>
                        </section>
                    </div>

                    <Modal
                        className="r-modal modal_no_padding"
                        maskClosable={false}
                        centered={true}
                        open={opeationModalOpen}
                        closeIcon={<></>}
                        onCancel={() => setOpeationModalOpen(false)}
                        footer={''}
                        key={`prizeDialog-enterM`}
                        style={{ width: 542 }}
                        title={[<></>]}
                        getContainer={false}
                    >
                        <div className="modal_form">
                            <p className="modal_tips">{`Are you sure you want to redeem ${
                                opeationPrize.needPoints ?? 0
                            } ${+opeationPrize.needPoints > 1 ? 'points' : 'point'} for this draw?`}</p>
                            <div className="r-left baseSize">
                                <Button
                                    className="r-cancel_btn"
                                    style={{ width: '100%' }}
                                    onClick={() => setOpeationModalOpen(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="r-primary_btn"
                                    loading={loading}
                                    disabled={loading}
                                    style={{ width: '100%' }}
                                    onClick={() => confirmEnter()}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Prize;
