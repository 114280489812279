import React, { useEffect, Fragment, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';
import { Popup } from 'react-vant';
import logo from '@/assets/img/logo.png';
import close from '@/assets/img/home/close.png';
import './index.scss';
import routes from '@/router';
import { useAliveController } from 'react-activation';
import { setUserStatus } from '@/store/stateSlice';
import { useDispatch } from 'react-redux';
const NProgress = props => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clear } = useAliveController();
    const routeList = routes.find(v => v.childrens?.length)?.childrens;
    const { deviceType, isFree, freeDay, profileInfo, userStatus } = useSelector((state: any) => state.state);
    const [open, setOpen] = useState(false);
    const [suspendedModalOpen, setSuspendedModalOpen] = useState(false);
    const [opeationModalOpen, setOpeationModalOpen] = useState(false);
    const [active, setActive] = useState(0);
    const [blackNav, setBlackNav] = useState(false);
    const blackNavList = ['/home', '/surveyRespond', '/prize', '/enterSuccess', '/profile'];
    const onClose = () => {
        setOpen(false);
    };
    const toHome = () => {
        if (props.type === 3) return;
        navigate('/website');
    };
    const navList = [
        {
            label: 'Home',
            url: '/home',
            key: 0,
        },
        {
            label: 'Categories',
            url: '/categories',
            key: 1,
        },
        {
            label: 'Profile',
            url: '/profile',
            key: 2,
        },
    ];
    const changeNav = val => {
        clear();
        setActive(val.key);
        setOpen(false);
        if (profileInfo.isExpired && !val.key) {
            navigate('/introductionPage?type=2');
            return;
        }
        navigate(val.url);
    };
    const confirmLogout = () => {
        dispatch(setUserStatus(1));
        setSuspendedModalOpen(false);
        window.sessionStorage.clear();
        window.localStorage.clear();
        navigate('/login');
    };
    useEffect(() => {
        const nowPath = location.pathname;
        const key = routeList?.find(v => v.path === nowPath)?.key ?? 0;
        setBlackNav(blackNavList.includes(nowPath));
        setActive(key);
        window.scrollTo(0, 0);
    }, [location]);
    useEffect(() => {
        if (userStatus !== 1 && userStatus !== undefined) {
            setSuspendedModalOpen(true);
        } else {
            setSuspendedModalOpen(false);
        }
    }, [userStatus]);
    return (
        <div>
            {deviceType === 'pc' ? (
                <div className="nav_top r-left">
                    <img src={logo} className="logo-box" onClick={() => toHome()} alt="" />
                    {/* type 1 login 2 register 3 user */}
                    {props.type === 1 ? (
                        <>
                            <p className="top-tip">Don't have an account yet?</p>
                            <div className="baseSize">
                                <Button className="r-primary_btn" onClick={() => navigate('/signUp')}>
                                    Sign Up
                                </Button>
                            </div>
                        </>
                    ) : props.type === 2 ? (
                        <>
                            <p className="top-tip">Already have an account?</p>
                            <div className="baseSize">
                                <Button className="r-primary_btn" onClick={() => navigate('/login')}>
                                    Log In
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            {/* {isFree && !Number(profileInfo.subscriptionId) ? (
                                <span className="sub_tip">
                                    {freeDay} {freeDay > 1 ? 'days' : 'day'} is left on your free trail.
                                </span>
                            ) : (
                                ''
                            )} */}
                            <ul className="r-left navUl">
                                {navList.map((item, i) => {
                                    return (
                                        <li
                                            key={i}
                                            className={`navItem ${
                                                Number(profileInfo.subscriptionId) === -1 && i > 0 ? 'noneBox' : ''
                                            } ${active === i ? 'active' : ''}`}
                                            onClick={() => changeNav(item)}
                                        >
                                            {item.label}
                                        </li>
                                    );
                                })}
                            </ul>
                            <Button className="r-primary_btn" onClick={() => setOpeationModalOpen(true)}>
                                Log out
                            </Button>
                        </>
                    )}
                </div>
            ) : (
                <div className={`nav_top r-left ${blackNav ? 'black_navBox' : ''}`}>
                    <i className="menu-icon" onClick={() => setOpen(true)}></i>
                    <img src={logo} className="logo-icon" onClick={() => toHome()} />
                </div>
            )}

            <Popup visible={open} key="navTop-popupbox" className="r-drawer" onClose={() => onClose()} position="left">
                <div className="r-left top-close">
                    <img src={logo} className="drawerLogo" alt="" />
                    <img src={close} className="drawerClose" onClick={() => setOpen(false)} alt="" />
                </div>
                {props.type === 1 ? (
                    <>
                        <div className="drawer-tip">Don't have an account yet?</div>
                        <div className="baseSize">
                            <Button className="r-primary_btn" onClick={() => navigate('/signUp')}>
                                Sign Up
                            </Button>
                        </div>
                    </>
                ) : props.type === 2 ? (
                    <>
                        <div className="drawer-tip">Already have an account?</div>
                        <div className="baseSize">
                            <Button className="r-primary_btn" onClick={() => navigate('/login')}>
                                Log In
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className="navDrawer-box">
                        <ul className="navUl">
                            {navList.map((item, i) => {
                                return (
                                    <li
                                        key={i}
                                        className={`navItem ${
                                            Number(profileInfo.subscriptionId) === -1 && i > 0 ? 'noneBox' : ''
                                        } ${active === i ? 'active' : ''}`}
                                        onClick={() => changeNav(item)}
                                    >
                                        {item.label}
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="baseSize">
                            {/* {isFree &&
                            !Number(profileInfo.subscriptionId) &&
                            !Number(profileInfo.subscriptionPackageId) ? (
                                <span className="sub_tip">
                                    {freeDay} {freeDay > 1 ? 'days' : 'day'} is left on your free trail.
                                </span>
                            ) : (
                                ''
                            )} */}
                            <Button
                                className="r-primary_btn"
                                onClick={() => {
                                    setOpen(false);
                                    setOpeationModalOpen(true);
                                }}
                            >
                                Log out
                            </Button>
                        </div>
                    </div>
                )}
            </Popup>
            {/* logout */}
            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={opeationModalOpen}
                closeIcon={<></>}
                onCancel={() => setOpeationModalOpen(false)}
                footer={''}
                key={`logout-modal`}
                style={{ width: 360 }}
                title={<></>}
                getContainer={false}
            >
                <div className="modal_form">
                    <p className="modal_tips">Are you sure to log out of the current account?</p>
                    <div className="r-left baseSize">
                        <Button
                            className="r-cancel_btn"
                            style={{ width: '100%' }}
                            onClick={() => setOpeationModalOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button className="r-primary_btn" style={{ width: '100%' }} onClick={() => confirmLogout()}>
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
            {/* disabled or del */}
            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                open={suspendedModalOpen}
                closeIcon={<></>}
                onCancel={() => setSuspendedModalOpen(false)}
                footer={''}
                key={`userisdisabled-modal`}
                style={{ width: 360 }}
                title={<></>}
                getContainer={false}
            >
                <div className="modal_form">
                    {userStatus !== 3 ? (
                        <div className="modal_tips">
                            Your account has been {userStatus === 2 ? 'suspended' : 'deleted'}. <br></br>Please contact
                            <div style={{ marginTop: 10 }}>
                                <a
                                    className="modal_tips"
                                    href="mailto:support@whatsyouropinion.com.au"
                                    style={{ color: '#FF7957', textDecoration: 'underline' }}
                                >
                                    support@whatsyouropinion.com.au
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div className="modal_tips">The account was detected as being logged in on another device.</div>
                    )}
                    <div className="r-left baseSize">
                        <Button className="r-primary_btn" style={{ width: '100%' }} onClick={() => confirmLogout()}>
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default NProgress;
