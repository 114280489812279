import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import api from '@/api/home';
import './style/index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import memberData from '@/utils/member';
import useLoading from '@/hook/useLoading';
import { useSelector } from 'react-redux';
import apiProfile from '@/api/profile';
import { setProfileInfo } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
import { MemberOptionContext } from '@/context/MemberOptionProvider';
const Home = (props: any) => {
    const dispatch = useAppDispatch();
    const { deviceType } = useSelector((state: any) => state.state);
    const memberOptions: any = useContext(MemberOptionContext);
    const subscriptionList = memberData.subscriptionList;
    const navigate = useNavigate();
    const { mainLoading, setLoadingStatus } = useLoading();
    const [subscriptionId, setSubscriptionId] = useState(0);
    const [memberKey, setMemberKey] = useState(0);
    const [state, setState] = useState({
        point: 0,
        subscriptionPackageId: 0,
        subscriptionId: 0,
        todayAnswerCount: 0,
        todayPointCount: 0,
    });
    const toUpdateMember = () => {
        navigate(
            `/memberOption?type=2&subscriptionId=${state.subscriptionId}&subscriptionPackageId=${state.subscriptionPackageId}`
        );
    };
    const taskQuestion = () => {
        if (+state.todayAnswerCount === 0) {
            window.utils.alert('error', 'The number of survey questions that can be answered today is 0.');
            return;
        }
        navigate('/surveyRespond');
    };
    const getInfo = () => {
        apiProfile.getUserProfileInfo(res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data && data !== null) {
                    setSubscriptionId(data.subscriptionId);
                    data.subscriptionId = data.subscriptionId;
                    data.isExpired = window.utils.isExpired(+data.subscriptionExpiryTime);
                    data.point = window.utils.formatPonit(data.point ?? 0);
                    data.subscriptionExpiryTimeShow =
                        data.subscriptionExpiryTimeShow !== null
                            ? window.utils.foramtTimeDMYTime(data.subscriptionExpiryTimeShow)
                            : '-';

                    dispatch(setProfileInfo({ ...data }));
                    if (data.isExpired) {
                        navigate('/introductionPage?type=2');
                    }
                }
                setLoadingStatus(false);
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const getHomeInfo = () => {
        api.getUserHomeInfo(res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data && data !== null) {
                    data.point = window.utils.formatPonit(data.point ?? 0);
                    data.todayPointCount = window.utils.formatPonit(data.todayPointCount ?? 0);
                    data.todayAnswerCount = window.utils.formatPonit(data.todayAnswerCount ?? 0);
                    setState({ ...state, ...data });
                    const index = subscriptionList.findIndex(v =>
                        v.subscriptionPackageIds.includes(+data.subscriptionPackageId)
                    );
                    setMemberKey(index !== -1 ? index : 0);
                }
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    useEffect(() => {
        window.sessionStorage.removeItem('questionIndex');
        setLoadingStatus(true);
        getHomeInfo();
        getInfo();
    }, []);
    return (
        <>
            {!mainLoading ? (
                <div className="home-main">
                    <div className="r-center">
                        <img src={logoIcon} className="logo-box" alt="" />
                    </div>
                    <div className="r-left profile-numBox">
                        <div className="statistics-card gift-card">
                            Your rewards balance
                            <div className="sta-num gift-num">{state.point}</div>
                        </div>
                        <div className="statistics-card">
                            Survey’s available today
                            <div className="sta-num">{state.todayAnswerCount}</div>
                        </div>
                        <div className="statistics-card">
                            Reward points available to earn today
                            <div className="sta-num">{state.todayPointCount}</div>
                        </div>
                    </div>
                    <div className="r-center">
                        <Button
                            className={`r-primary_btn home-nextBtn ${+state.todayAnswerCount === 0 ? 'disabled' : ''}`}
                            onClick={() => taskQuestion()}
                        >
                            Take a survey!
                        </Button>
                    </div>
                    <div className="r-left prizes-tit">
                        {+subscriptionId === 0
                            ? 'Prizes to be obtained during the trial period'
                            : subscriptionList[memberKey].updataTip}
                        <Button className="r-defaultBlick_btn marLeft" onClick={() => navigate('/prize')}>
                            See all prizes
                        </Button>
                    </div>
                    <div className="sub-card" key={memberOptions?.globalData[memberKey].tit}>
                        <div className="subCard_top r-left">
                            <img src={memberOptions?.globalData[memberKey].icon} className="sub-icon" alt="" />
                            <div>
                                <div className="sub-tit">{memberOptions?.globalData[memberKey].tit}</div>
                                <div
                                    className="sub-price r-left"
                                    style={{
                                        flexWrap: 'wrap',
                                        minHeight: deviceType === 'pc' ? 'auto' : 48,
                                        alignItems: 'center',
                                    }}
                                >
                                    {memberOptions?.globalData[memberKey].price}
                                    {memberOptions?.globalData[memberKey].isMax ? (
                                        <span className="maxIcBox">Maximum</span>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            {memberOptions?.globalData[memberKey].isMost ? (
                                <div className="mostBox">MOST POPULAR</div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="sub_content">
                            {memberOptions?.globalData[memberKey].infoList.map((val, i) => {
                                return (
                                    <div className="r-left subInfo_box" key={i}>
                                        <i className="check_icon"></i>
                                        <span className="sub_tip">{val}</span>
                                    </div>
                                );
                            })}
                            <div className="r-left subInfo_box sub_tipBtm">
                                <i className="tip_icon"></i>
                                <span className="sub_tip">{memberOptions?.globalData[memberKey].tip}</span>
                            </div>
                            <div className="baseSize">
                                {+subscriptionId !== 3 ? (
                                    <Button
                                        className="r-defaultBlick_btn homeSign-btn"
                                        onClick={() => {
                                            toUpdateMember();
                                        }}
                                    >
                                        Sign Up
                                    </Button>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Home;
