import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Divider } from 'antd';
import './index.scss';
import logoIcon from '@/assets/img/logo@2x.png';
import { useSelector } from 'react-redux';
import apiProfile from '@/api/profile';
import memberData from '@/utils/member';
import { setProfileInfo } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
import { MemberOptionContext } from '@/context/MemberOptionProvider';
const Pay = (props: any) => {
    const memberOptions: any = useContext(MemberOptionContext);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get('type') ?? '1'; // 1 引导页 2 升级会员选择套餐页 3 免费
    const navigate = useNavigate();
    const subscriptionList = memberData.subscriptionList;
    const memberOptionList = memberData.memberOptionList;
    const [state, setState] = useState({
        checkIsFirstCharge: false,
        subscriptionPackageId: '',
    });
    const [memberKey, setMemberKey] = useState(1);
    const [memberInfo, setmMemberInfo] = useState<any>({
        date: '1 YEAR',
        price: '25.00',
        pts: '20,000 PTS',
        ptsNum: '20,000',
        dicount: '',
        tip: 'When you sign up, you receive these promotional starter points',
    });
    const memberInfoFree = {
        date: '28 DAYS',
        price: '0.00',
        pts: '15,000 PTS',
        dicount: '',
        tip: [
            'Sign up for 28 days free',
            'Compete surveys & accumulate rewards points',
            'Watch your balance grow',
            'After 28 days choose your membership & receive 15,000 sign up bonus points.',
        ],
    };
    const nextStep = () => {
        navigate(Number(type) === 2 ? '/home' : '/categories?type=2');
    };
    const getInfo = val => {
        apiProfile.getUserProfileInfo(res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data && data !== null) {
                    data.subscriptionId = val ? val.subscriptionId : data.subscriptionId;
                    data.isExpired = window.utils.isExpired(+data.subscriptionExpiryTime);
                    data.point = window.utils.formatPonit(data.point ?? 0);
                    data.subscriptionExpiryTimeShow =
                        data.subscriptionExpiryTimeShow !== null
                            ? window.utils.foramtTimeDMYTime(data.subscriptionExpiryTimeShow)
                            : '-';

                    dispatch(setProfileInfo({ ...data }));
                }
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    const subscriptionPackageIdList = [
        [1, 4, 7],
        [2, 5, 8],
        [3, 6, 9],
    ];
    useEffect(() => {
        const info = JSON.parse(window.sessionStorage.getItem('paySuccessInfo') ?? '{}');
        const payMemberInfo = JSON.parse(window.sessionStorage.getItem('payMemberInfo') ?? '{}');
        if (info.paymentIntentId) {
            setState({ ...info });
            const index = subscriptionList.findIndex(v =>
                v.subscriptionPackageIds.includes(+info.subscriptionPackageId)
            );
            setMemberKey(index !== -1 ? index : 0);
            const packageInfo: any = memberOptionList[index].priceList.find(
                v => +v.subscriptionPackageId === +info.subscriptionPackageId
            );
            // if (info.checkIsFirstCharge) {
            //     // 首次加15000
            //     const newNum = packageInfo.ptsNum + 15000;
            //     packageInfo.ptsNumShow = window.utils.formatPonit(newNum);
            // } else {
            //     packageInfo.ptsNumShow = window.utils.formatPonit(packageInfo?.ptsNum ?? '0');
            // }
            packageInfo.ptsNumShow = window.utils.formatPonit(packageInfo?.ptsNum ?? '0');
            setmMemberInfo({ ...packageInfo });
            if (Number(type) !== 3) {
                const val = payMemberInfo ? payMemberInfo : { subscriptionId: '' };
                const i = subscriptionPackageIdList.findIndex(v => v.includes(Number(info.subscriptionPackageId)));
                val.subscriptionId = i + 1;
                setTimeout(() => {
                    getInfo(val);
                }, 2000);
            }
        }
    }, []);
    return (
        <div className="member-confirm-main r-center">
            <img src={logoIcon} className="logo-box" alt="" />
            <div className="order-rightBox">
                <h3 className="order-tit">Congrats!</h3>
                <div className="order-tip orderTop-tip">
                    {Number(type) === 3 ? (
                        "You've got a 28-day free trial"
                    ) : (
                        <span>You have received {memberInfo.ptsNumShow ?? '0'} reward points</span>
                    )}
                    {/* {state.checkIsFirstCharge && Number(type) !== 3 ? (
                        <p>(Includes 15,000 sign up reward points)</p>
                    ) : (
                        ''
                    )} */}
                </div>
                <p className="order-tip member-msg">
                    {Number(type) === 3 ? 'Freemium' : `${subscriptionList[memberKey].tit} membership summary`}
                </p>
                {Number(type) === 3 ? (
                    // 免费
                    <div className="pad24">
                        <div className={`price-card`}>
                            <div className="price-date">{memberInfoFree.date}</div>
                            <div className="r-left">
                                <div className="price-box r-left">
                                    <span className="priceLef">$ </span>
                                    <span className="priceNum">{memberInfoFree.price}</span>
                                </div>
                                <div className="price-pts">{memberInfoFree.pts}</div>
                            </div>
                            {memberInfoFree.tip.map((val, i) => {
                                return (
                                    <div className={`price-tip ${i ? 'mar20' : ''}`} key={i}>
                                        {val}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    // 付费
                    <div className="pad24">
                        <div className={`price-card`}>
                            <div className="price-date">{memberInfo.date}</div>
                            <div className="r-left">
                                <div className="price-box r-left">
                                    <span className="priceLef">$ </span>
                                    <span className="priceNum">{memberInfo.price}</span>
                                </div>
                                <div className="price-pts">{memberInfo.pts}</div>
                            </div>
                            <div className={`price-tip `}>{memberInfo.tip}</div>
                        </div>
                    </div>
                )}
                {Number(type) !== 3 ? (
                    <div className="sub-card">
                        <div className="subCard_top r-left">
                            <img src={memberOptions?.globalData[memberKey].icon} className="sub-icon" alt="" />
                            <div>
                                <div className="sub-tit">{memberOptions?.globalData[memberKey].tit}</div>
                                <div className="sub-price r-left">
                                    {memberOptions?.globalData[memberKey].price}
                                    {memberOptions?.globalData[memberKey].isMax ? (
                                        <span className="maxIcBox">Maximum</span>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                            {/* {subscriptionList[memberKey].isMost ? <div className="mostBox">MOST POPULAR</div> : ''} */}
                        </div>
                        <div className="sub_content">
                            {memberOptions?.globalData[memberKey].infoList.map((val, i) => {
                                return (
                                    <div className="r-left subInfo_box" key={i}>
                                        <i className="check_icon"></i>
                                        <span className="sub_tip">{val}</span>
                                    </div>
                                );
                            })}
                            <div className="r-left subInfo_box sub_tipBtm">
                                <i className="tip_icon"></i>
                                <span className="sub_tip">{memberOptions?.globalData[memberKey].tip}</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <div className="r-left order-opeationBox">
                    <Button className="r-primary_btn w100" onClick={() => nextStep()}>
                        {/* {Number(type) === 3 ? 'Continue' : 'Confirm'} */}
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Pay;
